import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Calendar, PlayCircle, Dumbbell, BarChart2, MessageCircle, Settings as SettingsIcon } from 'lucide-react';
import MoveWellHome from './components/MoveWellHome';
import CalendarPage from './components/Calendar';
import MovementBreak from './components/MovementBreak';
import ExerciseLibrary from './components/ExerciseLibrary';
import ProgressDashboard from './components/ProgressDashboard';
import Settings from './components/Settings';
import ChatPage from './components/ChatPage';

const NavLink = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  return (
    <Link 
      to={to} 
      className={`hover:text-teal-400 transition-colors duration-200 ${isActive ? 'text-teal-400' : 'text-gray-300'}`}
    >
      {children}
    </Link>
  );
};

function App() {
  return (
    <Router>
      <div className="App bg-gray-900 min-h-screen flex flex-col text-gray-100">
        <nav className="bg-gray-800 text-gray-100 p-4 shadow-md">
          <div className="max-w-7xl mx-auto flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-teal-400 hover:text-teal-300 transition-colors duration-200">MoveWell</Link>
            <div className="flex space-x-4">
              <NavLink to="/calendar"><Calendar size={24} /></NavLink>
              <NavLink to="/movement-break"><PlayCircle size={24} /></NavLink>
              <NavLink to="/exercise-library"><Dumbbell size={24} /></NavLink>
              <NavLink to="/progress"><BarChart2 size={24} /></NavLink>
              <NavLink to="/chat"><MessageCircle size={24} /></NavLink>
              <NavLink to="/settings"><SettingsIcon size={24} /></NavLink>
            </div>
          </div>
        </nav>
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<MoveWellHome />} />
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="/movement-break" element={<MovementBreak />} />
            <Route path="/exercise-library" element={<ExerciseLibrary />} />
            <Route path="/progress" element={<ProgressDashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/chat" element={<ChatPage />} />
          </Routes>
        </main>
        <footer className="bg-gray-800 text-center p-4 text-gray-400 text-sm">
          © 2023 MoveWell. All rights reserved.
        </footer>
      </div>
    </Router>
  );
}

export default App;