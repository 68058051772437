const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

export const scheduledBreaks = [
  { 
    title: 'Lunch Break Walk', 
    start: new Date(today.setHours(12, 30, 0, 0)), 
    end: new Date(today.setHours(13, 0, 0, 0)), 
    type: 'break' 
  },
  { 
    title: 'Afternoon Stretch', 
    start: new Date(today.setHours(15, 0, 0, 0)), 
    end: new Date(today.setHours(15, 15, 0, 0)), 
    type: 'break' 
  },
  { 
    title: 'Evening Relaxation', 
    start: new Date(today.setHours(17, 30, 0, 0)), 
    end: new Date(today.setHours(17, 45, 0, 0)), 
    type: 'break' 
  },
  { 
    title: 'Morning Yoga', 
    start: new Date(tomorrow.setHours(9, 0, 0, 0)), 
    end: new Date(tomorrow.setHours(9, 30, 0, 0)), 
    type: 'break' 
  },
];