import React, { useState, useEffect } from 'react';
import { Search, Heart, Play } from 'lucide-react';
import { Link } from 'react-router-dom';

const exercises = [
  { id: 0, name: 'Hamstrings Exercise', duration: 40, category: 'Relief', video: '/videos/Hamstrings.mp4', location: 'Home', position: 'Standing', target: 'Lower Body' },
  { id: 1, name: 'Ab Circuit', duration: 300, category: 'Strength', video: '/videos/abs.mp4', location: 'Home', position: 'Sitting', target: 'Full Body' },
  { id: 2, name: 'Desk Stretches', duration: 45, category: 'Relief', video: '/videos/desk-stretches.mp4', location: 'Work', position: 'Sitting', target: 'Upper Body' },
  { id: 3, name: 'Quick Cardio Burst', duration: 30, category: 'Strength', video: '/videos/cardio-burst.mp4', location: 'Work', position: 'Standing', target: 'Full Body' },
  { id: 4, name: 'Lower Back Relaxation', duration: 60, category: 'Relax', video: '/videos/lower-back.mp4', location: 'Home', position: 'Sitting', target: 'Lower Body' },
  { id: 5, name: 'Wrist and Hand Exercises', duration: 45, category: 'Relief', video: '/videos/wrist-hand.mp4', location: 'Work', position: 'Sitting', target: 'Upper Body' },
  { id: 6, name: 'Office Yoga', duration: 180, category: 'Relax', video: '/videos/office-yoga.mp4', location: 'Work', position: 'Standing', target: 'Full Body' },
  { id: 7, name: 'Standing Desk Workout', duration: 120, category: 'Strength', video: '/videos/standing-desk.mp4', location: 'Work', position: 'Standing', target: 'Full Body' },
  { id: 8, name: 'Eye Strain Relief', duration: 60, category: 'Relief', video: '/videos/eye-strain.mp4', location: 'Work', position: 'Sitting', target: 'Upper Body' },
  { id: 9, name: 'Posture Correction Routine', duration: 90, category: 'Relief', video: '/videos/posture.mp4', location: 'Home', position: 'Standing', target: 'Full Body' },
  { id: 10, name: 'Chair Squats', duration: 60, category: 'Strength', video: '/videos/chair-squats.mp4', location: 'Work', position: 'Standing', target: 'Lower Body' },
  { id: 11, name: 'Outdoor Walk', duration: 600, category: 'Walk', video: '/videos/outdoor-walk.mp4', location: 'Outdoor', position: 'Standing', target: 'Full Body' },
];

const ExerciseLibrary = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('All');
  const [locationFilter, setLocationFilter] = useState('All');
  const [positionFilter, setPositionFilter] = useState('All');
  const [targetFilter, setTargetFilter] = useState('All');
  const [favorites, setFavorites] = useState([]);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
    setFavorites(storedFavorites);
  }, []);

  const toggleFavorite = (id) => {
    const newFavorites = favorites.includes(id)
      ? favorites.filter(favId => favId !== id)
      : [...favorites, id];
    setFavorites(newFavorites);
    localStorage.setItem('favorites', JSON.stringify(newFavorites));
  };

  const filteredExercises = exercises.filter(exercise => 
    exercise.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (categoryFilter === 'All' || exercise.category === categoryFilter) &&
    (locationFilter === 'All' || exercise.location === locationFilter) &&
    (positionFilter === 'All' || exercise.position === positionFilter) &&
    (targetFilter === 'All' || exercise.target === targetFilter) &&
    (!showFavoritesOnly || favorites.includes(exercise.id))
  );

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-gray-100">Exercise Library</h1>
      
      <div className="mb-6 flex flex-wrap items-center gap-4">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search exercises..."
            className="w-full bg-gray-700 text-white p-2 pl-10 rounded-md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        
        <select 
          className="bg-gray-700 text-white p-2 rounded-md"
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
        >
          <option value="All">All Categories</option>
          <option value="Relief">Relief</option>
          <option value="Strength">Strength</option>
          <option value="Walk">Walk</option>
          <option value="Relax">Relax</option>
        </select>

        <select 
          className="bg-gray-700 text-white p-2 rounded-md"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="All">All Locations</option>
          <option value="Home">Home</option>
          <option value="Work">Work</option>
          <option value="Outdoor">Outdoor</option>
        </select>

        <select 
          className="bg-gray-700 text-white p-2 rounded-md"
          value={positionFilter}
          onChange={(e) => setPositionFilter(e.target.value)}
        >
          <option value="All">All Positions</option>
          <option value="Standing">Standing</option>
          <option value="Sitting">Sitting</option>
        </select>

        <select 
          className="bg-gray-700 text-white p-2 rounded-md"
          value={targetFilter}
          onChange={(e) => setTargetFilter(e.target.value)}
        >
          <option value="All">All Targets</option>
          <option value="Upper Body">Upper Body</option>
          <option value="Lower Body">Lower Body</option>
          <option value="Full Body">Full Body</option>
        </select>

        <button
          className={`p-2 rounded-md ${showFavoritesOnly ? 'bg-teal-500 text-white' : 'bg-gray-700 text-gray-300'}`}
          onClick={() => setShowFavoritesOnly(!showFavoritesOnly)}
        >
          <Heart size={20} />
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredExercises.map(exercise => (
          <div key={exercise.id} className="bg-gray-800 p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2 text-gray-100">{exercise.name}</h2>
            <p className="text-gray-300 mb-1">Duration: {exercise.duration} seconds</p>
            <p className="text-gray-300 mb-1">Category: {exercise.category}</p>
            <p className="text-gray-300 mb-1">Location: {exercise.location}</p>
            <p className="text-gray-300 mb-1">Position: {exercise.position}</p>
            <p className="text-gray-300 mb-4">Target: {exercise.target}</p>
            <div className="flex justify-between items-center">
              <Link 
                to={`/movement-break?video=${exercise.video}&name=${exercise.name}`} 
                className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                <Play size={20} className="mr-2" />
                Start Exercise
              </Link>
              <button 
                onClick={() => toggleFavorite(exercise.id)}
                className={`p-2 rounded-full ${
                  favorites.includes(exercise.id) ? 'bg-pink-500 text-white' : 'bg-gray-700 text-gray-300'
                }`}
              >
                <Heart size={20} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExerciseLibrary;