import React, { useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';
import { scheduledBreaks } from '../data/sharedData';
import { Link, FileDown, Calendar as CalendarIcon, Clock, CheckCircle, XCircle } from 'lucide-react';

const localizer = momentLocalizer(moment);

const today = new Date();

const initialEvents = [
  ...scheduledBreaks,
  {
    title: 'Stretching',
    start: new Date(today.setHours(10, 30, 0, 0)),
    end: new Date(today.setHours(10, 45, 0, 0)),
    type: 'break',
  },
  {
    title: 'Quick walk',
    start: new Date(today.setHours(13, 0, 0, 0)),
    end: new Date(today.setHours(13, 15, 0, 0)),
    type: 'break',
  },
  {
    title: 'Desk exercises',
    start: new Date(today.setHours(15, 30, 0, 0)),
    end: new Date(today.setHours(15, 45, 0, 0)),
    type: 'break',
  },
];

const CalendarPage = () => {
  const [events, setEvents] = useState(initialEvents);
  const [suggestedBreak, setSuggestedBreak] = useState(null);
  const calendarFeedUrl = 'https://movewell.rogerlam.co/movewell-breaks.ics';

  const handleSelectSlot = ({ start, end }) => {
    const title = window.prompt('Enter a title for your new break');
    if (title) {
      const newEvent = { start, end, title, type: 'break' };
      setEvents([...events, newEvent]);
    }
  };

  const suggestBreak = () => {
    const now = new Date();
    const suggestion = new Date(now.setHours(now.getHours() + 1));
    setSuggestedBreak({
      title: 'Suggested Break',
      start: suggestion,
      end: new Date(suggestion.getTime() + 15*60000), // 15 minutes later
      type: 'suggested',
    });
  };

  const acceptSuggestedBreak = () => {
    if (suggestedBreak) {
      setEvents([...events, { ...suggestedBreak, type: 'break' }]);
      setSuggestedBreak(null);
    }
  };

  const declineSuggestedBreak = () => {
    setSuggestedBreak(null);
  };

  const eventStyleGetter = (event) => {
    let style = {
      backgroundColor: '#14B8A6',  // Teal color for all events
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    };

    if (event.type === 'suggested') {
      style.backgroundColor = '#F59E0B';  // Yellow for suggested breaks
    }

    return {
      style: style
    };
  };

  const generateGoogleCalendarLink = () => {
    return `https://calendar.google.com/calendar/r?cid=${encodeURIComponent(calendarFeedUrl)}`;
  };

  const generateOutlookCalendarLink = () => {
    return `https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addsubscription&url=${encodeURIComponent(calendarFeedUrl)}&name=${encodeURIComponent('MoveWell Breaks')}`;
  };

  const generateAppleCalendarLink = () => {
    return `webcal://${calendarFeedUrl.split('://')[1]}`;
  };

  const EventComponent = ({ event }) => (
    <div className="text-sm">
      <strong>{event.title}</strong>
    </div>
  );

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-gray-100 text-center">MoveWell Calendar</h1>
      <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-4 sm:p-6 rounded-lg shadow-xl mb-6">
        <div className="h-[500px] sm:h-[600px]">
          <BigCalendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%' }}
            onSelectSlot={handleSelectSlot}
            selectable
            eventPropGetter={eventStyleGetter}
            defaultView="agenda"
            views={['month', 'week', 'day', 'agenda']}
            min={new Date(today.setHours(7, 0, 0, 0))} // 7 AM
            max={new Date(today.setHours(19, 0, 0, 0))} // 7 PM
            components={{
              event: EventComponent
            }}
          />
        </div>
      </div>
      <div className="bg-gradient-to-br from-gray-700 to-gray-800 p-4 sm:p-6 rounded-lg shadow-xl mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-gray-100">Subscribe to Calendar</h2>
        <p className="text-gray-300 mb-6">
          Stay on track with your MoveWell breaks by adding them to your preferred calendar app:
        </p>
        <div className="space-y-6">
          <div>
            <h3 className="font-semibold text-gray-200 mb-2">Calendar Feed URL:</h3>
            <div className="flex flex-wrap items-center bg-gray-600 p-2 rounded">
              <input 
                type="text" 
                value={calendarFeedUrl} 
                readOnly 
                className="flex-grow bg-transparent text-gray-100 outline-none mb-2 sm:mb-0"
              />
              <div className="flex w-full sm:w-auto space-x-2">
                <button 
                  onClick={() => navigator.clipboard.writeText(calendarFeedUrl)}
                  className="flex-1 sm:flex-none bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded inline-flex items-center justify-center transition duration-300 ease-in-out"
                >
                  <Link size={16} className="mr-2" />
                  Copy URL
                </button>
                <a 
                  href={calendarFeedUrl}
                  download="movewell-breaks.ics"
                  className="flex-1 sm:flex-none bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center justify-center transition duration-300 ease-in-out"
                >
                  <FileDown size={16} className="mr-2" />
                  Download .ics
                </a>
              </div>
            </div>
          </div>
          <div>
            <h3 className="font-semibold text-gray-200 mb-2">Quick Subscribe:</h3>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <a 
                href={generateGoogleCalendarLink()} 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded inline-flex items-center justify-center transition duration-300 ease-in-out"
              >
                <CalendarIcon size={20} className="mr-2" />
                Google Calendar
              </a>
              <a 
                href={generateOutlookCalendarLink()} 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded inline-flex items-center justify-center transition duration-300 ease-in-out"
              >
                <CalendarIcon size={20} className="mr-2" />
                Outlook Calendar
              </a>
              <a 
                href={generateAppleCalendarLink()} 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-3 px-4 rounded inline-flex items-center justify-center transition duration-300 ease-in-out"
              >
                <CalendarIcon size={20} className="mr-2" />
                Apple Calendar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <button 
          onClick={suggestBreak} 
          className="w-full sm:w-auto mb-4 sm:mb-0 bg-gradient-to-r from-teal-400 to-teal-600 hover:from-teal-500 hover:to-teal-700 text-white font-bold py-3 px-6 rounded-full inline-flex items-center justify-center transition duration-300 ease-in-out transform hover:scale-105"
        >
          <Clock size={20} className="mr-2" />
          Suggest Break
        </button>
        {suggestedBreak && (
          <div className="w-full sm:w-auto bg-gradient-to-r from-gray-700 to-gray-800 p-4 rounded-lg shadow-md">
            <p className="text-white mb-3">
              <span className="font-semibold">Suggested break:</span> {suggestedBreak.start.toLocaleTimeString()} - {suggestedBreak.end.toLocaleTimeString()}
            </p>
            <div className="flex justify-end space-x-2">
              <button 
                onClick={acceptSuggestedBreak} 
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out"
              >
                <CheckCircle size={16} className="mr-2" />
                Accept
              </button>
              <button 
                onClick={declineSuggestedBreak} 
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out"
              >
                <XCircle size={16} className="mr-2" />
                Decline
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarPage;