import React from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Clock, Zap, TrendingUp, AlertCircle } from 'lucide-react';

const weeklyData = [
  { name: 'Mon', standMinutes: 300, exerciseMinutes: 30, sedentaryMinutes: 510, breaks: 5 },
  { name: 'Tue', standMinutes: 312, exerciseMinutes: 36, sedentaryMinutes: 492, breaks: 7 },
  { name: 'Wed', standMinutes: 306, exerciseMinutes: 30, sedentaryMinutes: 504, breaks: 6 },
  { name: 'Thu', standMinutes: 318, exerciseMinutes: 42, sedentaryMinutes: 480, breaks: 8 },
  { name: 'Fri', standMinutes: 312, exerciseMinutes: 36, sedentaryMinutes: 492, breaks: 9 },
  { name: 'Sat', standMinutes: 288, exerciseMinutes: 24, sedentaryMinutes: 528, breaks: 4 },
  { name: 'Sun', standMinutes: 270, exerciseMinutes: 18, sedentaryMinutes: 552, breaks: 3 },
];

const weeklyDataInHours = weeklyData.map(day => ({
  ...day,
  standHours: day.standMinutes / 60,
  sedentaryHours: day.sedentaryMinutes / 60
}));

const today = new Date();
const dayOfWeek = today.getDay();
const todayData = weeklyData[dayOfWeek === 0 ? 6 : dayOfWeek - 1];

const MoveWellHome = () => {
  const dailyGoal = 10; // Example daily goal for breaks
  const progress = (todayData.breaks / dailyGoal) * 100;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-gray-800 p-4 rounded shadow-lg border border-gray-700">
          <p className="text-gray-300 font-bold">{label}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {entry.name}: {entry.name.includes('Hours') 
                ? `${entry.value.toFixed(1)} hours`
                : `${entry.value} minutes`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-gray-100">MoveWell Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <Clock className="text-teal-400 mr-2" size={24} />
            <h2 className="text-xl font-semibold text-gray-100">Stand Hours</h2>
          </div>
          <p className="text-3xl font-bold text-gray-100">{(todayData.standMinutes / 60).toFixed(1)} hrs</p>
          <p className="text-gray-400">Today</p>
        </div>
        
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <Zap className="text-teal-400 mr-2" size={24} />
            <h2 className="text-xl font-semibold text-gray-100">Exercise Minutes</h2>
          </div>
          <p className="text-3xl font-bold text-gray-100">{todayData.exerciseMinutes} mins</p>
          <p className="text-gray-400">Today</p>
        </div>
        
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <TrendingUp className="text-teal-400 mr-2" size={24} />
            <h2 className="text-xl font-semibold text-gray-100">Sedentary Time</h2>
          </div>
          <p className="text-3xl font-bold text-gray-100">{(todayData.sedentaryMinutes / 60).toFixed(1)} hrs</p>
          <p className="text-gray-400">Today</p>
        </div>
      </div>
      
      <div className="bg-gray-800 p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-100">Daily Progress (Breaks)</h2>
        <div className="mb-2 flex justify-between">
          <span className="text-gray-300">Breaks Goal: {dailyGoal}</span>
          <span className="text-gray-300">{todayData.breaks} / {dailyGoal}</span>
        </div>
        <div className="w-full bg-gray-700 rounded-full h-2.5">
          <div 
            className="bg-gradient-to-r from-teal-500 to-blue-500 h-2.5 rounded-full" 
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="mt-2 text-right text-gray-400">{progress.toFixed(0)}% complete</p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-100">Weekly Stand vs Sedentary Time</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={weeklyDataInHours} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="name" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" label={{ value: 'Hours', angle: -90, position: 'insideLeft', fill: '#9CA3AF' }} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="standHours" stackId="a" name="Stand Hours" fill="url(#standGradient)" />
              <Bar dataKey="sedentaryHours" stackId="a" name="Sedentary Hours" fill="url(#sedentaryGradient)" />
              <defs>
                <linearGradient id="standGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#4ade80" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#22d3ee" stopOpacity={0.8}/>
                </linearGradient>
                <linearGradient id="sedentaryGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#f87171" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#fb923c" stopOpacity={0.8}/>
                </linearGradient>
              </defs>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-100">Weekly Exercise Time</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={weeklyData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="name" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" label={{ value: 'Minutes', angle: -90, position: 'insideLeft', fill: '#9CA3AF' }} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line type="monotone" dataKey="exerciseMinutes" name="Exercise" stroke="#3b82f6" strokeWidth={2} dot={{ r: 4 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      
      <div className="bg-gray-800 p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-100">Health Insights</h2>
        <div className="space-y-4">
          <div className="flex items-start">
            <AlertCircle className="text-yellow-500 mr-2 mt-1 flex-shrink-0" size={20} />
            <p className="text-gray-300">
              "All amounts of walking significantly reduced blood pressure by 4 to 5 mmHg compared with sitting all day. This is a sizeable decrease, comparable to the reduction you would expect from exercising daily for six months."
              <span className="text-gray-400 ml-2">- Columbia University Study</span>
            </p>
          </div>
          <div className="flex items-start">
            <AlertCircle className="text-green-500 mr-2 mt-1 flex-shrink-0" size={20} />
            <p className="text-gray-300">
              "All walking regimens, except walking one minute every hour, led to significant decreases in fatigue and significant improvements in mood."
              <span className="text-gray-400 ml-2">- Columbia University Study</span>
            </p>
          </div>
          <div className="flex items-start">
            <AlertCircle className="text-blue-500 mr-2 mt-1 flex-shrink-0" size={20} />
            <p className="text-gray-300">
              "Aim for about 5 hours of standing and 30 minutes of exercise daily for optimal health benefits."
              <span className="text-gray-400 ml-2">- Health Guidelines</span>
            </p>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4 text-gray-100">Upcoming Breaks</h2>
        <ul className="space-y-2">
          <li className="text-gray-300">Lunch Break Walk - 12:30 PM</li>
          <li className="text-gray-300">Afternoon Stretch - 3:00 PM</li>
          <li className="text-gray-300">Evening Relaxation - 5:30 PM</li>
        </ul>
      </div>
    </div>
  );
};

export default MoveWellHome;