import React, { useState, useEffect, useRef } from 'react';
import { RotateCcw, Volume2, VolumeX } from 'lucide-react';
import { useLocation } from 'react-router-dom';

const defaultExercises = [
  { name: 'Hamstrings Exercise', duration: 40, video: '/videos/Hamstrings.mp4' },
  { name: 'Ab Circuit', duration: 300, video: '/videos/abs.mp4' },
  { name: 'Desk Stretches', duration: 45, video: '/videos/desk-stretches.mp4' },
  { name: 'Quick Cardio Burst', duration: 30, video: '/videos/cardio-burst.mp4' },
  { name: 'Lower Back Relaxation', duration: 60, video: '/videos/lower-back.mp4' },
  { name: 'Wrist and Hand Exercises', duration: 45, video: '/videos/wrist-hand.mp4' }
];

const MovementBreak = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialExerciseName = queryParams.get('name') || 'Hamstrings Exercise';
  
  const initialExercise = defaultExercises.find(e => e.name === initialExerciseName) || defaultExercises[0];

  const [currentExercise, setCurrentExercise] = useState(initialExercise);
  const [isActive, setIsActive] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(initialExercise.duration);
  const videoRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    setTimeLeft(currentExercise.duration);
  }, [currentExercise]);

  useEffect(() => {
    if (isActive && countdown > 0) {
      timerRef.current = setTimeout(() => {
        setCountdown(prevCount => prevCount - 1);
      }, 1000);
    } else if (isActive && countdown === 0) {
      setIsVideoPlaying(true);
      videoRef.current.play();
      startTimer();
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isActive, countdown]);

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          setIsActive(false);
          setIsVideoPlaying(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const handleVideoClick = () => {
    if (!isActive) {
      setIsActive(true);
      setCountdown(3);
    } else if (isVideoPlaying) {
      videoRef.current.pause();
      setIsVideoPlaying(false);
      clearInterval(timerRef.current);
    } else {
      videoRef.current.play();
      setIsVideoPlaying(true);
      startTimer();
    }
  };

  const resetTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setIsActive(false);
    setCountdown(3);
    setIsVideoPlaying(false);
    setTimeLeft(currentExercise.duration);
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  const selectExercise = (exercise) => {
    setCurrentExercise(exercise);
    resetTimer();
  };

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-gray-100">Movement Break: {currentExercise.name}</h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <div className="bg-gray-800 p-4 rounded-lg shadow-md mb-6">
            <div 
              className="relative aspect-[9/16] mb-4 cursor-pointer"
              onClick={handleVideoClick}
            >
              {!isVideoPlaying && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 text-white text-6xl font-bold">
                  {!isActive ? "Click to Start" : isActive && countdown > 0 ? countdown : "Pause"}
                </div>
              )}
              <video
                ref={videoRef}
                className="w-full h-full object-cover rounded-lg"
                src={currentExercise.video}
                loop
                muted={isMuted}
                playsInline
              >
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="text-4xl font-bold mb-4 text-center text-teal-400">
              {isActive && isVideoPlaying ? `${timeLeft}s` : "Paused"}
            </div>
            <div className="flex justify-center space-x-4">
              <button 
                onClick={resetTimer} 
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                <RotateCcw size={24} />
              </button>
              <button 
                onClick={toggleMute} 
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-gray-800 p-4 rounded-lg shadow-md mb-6">
            <h3 className="text-xl font-semibold mb-4 text-gray-100">Choose Exercise</h3>
            <ul className="space-y-2">
              {defaultExercises.map((exercise, index) => (
                <li 
                  key={index} 
                  className={`p-2 rounded cursor-pointer ${
                    exercise.name === currentExercise.name ? 'bg-teal-500 text-white' : 'text-gray-300 hover:bg-gray-700'
                  }`}
                  onClick={() => selectExercise(exercise)}
                >
                  {exercise.name} ({exercise.duration}s)
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-100">Upcoming Breaks</h3>
            <ul className="space-y-2">
              <li className="text-gray-300">Lunch Break Walk - 12:30 PM</li>
              <li className="text-gray-300">Afternoon Stretch - 3:00 PM</li>
              <li className="text-gray-300">Evening Relaxation - 5:30 PM</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovementBreak;