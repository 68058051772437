import React, { useState } from 'react';
import { Save, Bell, Lock, Clock, TrendingUp, Link2, Check } from 'lucide-react';

const Settings = () => {
  const [settings, setSettings] = useState({
    breakDuration: 5,
    breakFrequency: 60,
    dailyBreakGoal: 10,
    weeklyActiveMinutesGoal: 300,
    appleHealthConnected: true,
    googleCalendarConnected: false,
    outlookCalendarConnected: true,
    notificationPreference: 'push',
    dataSharing: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Settings saved:', settings);
    // Implement actual save functionality
  };

  const handleManageIntegration = (integration) => {
    // Implement the logic to manage each integration
    console.log(`Managing ${integration} integration`);
    // This would typically open a modal or navigate to a new page for managing the integration
  };

  return (
    <div className="p-4 max-w-3xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-gray-100">Settings</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-100 flex items-center">
            <Clock className="mr-2" size={24} />
            Break Settings
          </h2>
          <div className="space-y-4">
            <div>
              <label htmlFor="breakDuration" className="block text-sm font-medium text-gray-300">Break Duration (minutes)</label>
              <input
                type="number"
                id="breakDuration"
                name="breakDuration"
                value={settings.breakDuration}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-gray-100"
              />
            </div>
            <div>
              <label htmlFor="breakFrequency" className="block text-sm font-medium text-gray-300">Break Frequency (minutes)</label>
              <input
                type="number"
                id="breakFrequency"
                name="breakFrequency"
                value={settings.breakFrequency}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-gray-100"
              />
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-100 flex items-center">
            <TrendingUp className="mr-2" size={24} />
            Goals
          </h2>
          <div className="space-y-4">
            <div>
              <label htmlFor="dailyBreakGoal" className="block text-sm font-medium text-gray-300">Daily Break Goal</label>
              <input
                type="number"
                id="dailyBreakGoal"
                name="dailyBreakGoal"
                value={settings.dailyBreakGoal}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-gray-100"
              />
            </div>
            <div>
              <label htmlFor="weeklyActiveMinutesGoal" className="block text-sm font-medium text-gray-300">Weekly Active Minutes Goal</label>
              <input
                type="number"
                id="weeklyActiveMinutesGoal"
                name="weeklyActiveMinutesGoal"
                value={settings.weeklyActiveMinutesGoal}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-gray-100"
              />
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-100 flex items-center">
            <Link2 className="mr-2" size={24} />
            Integrations
          </h2>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-gray-300">Apple Health</span>
              <div className="flex items-center">
                {settings.appleHealthConnected && (
                  <span className="text-green-500 flex items-center mr-2">
                    <Check size={16} className="mr-1" /> Connected
                  </span>
                )}
                <button
                  type="button"
                  onClick={() => handleManageIntegration('Apple Health')}
                  className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-1 px-3 rounded text-sm"
                >
                  Manage
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-300">Google Calendar</span>
              <div className="flex items-center">
                {settings.googleCalendarConnected && (
                  <span className="text-green-500 flex items-center mr-2">
                    <Check size={16} className="mr-1" /> Connected
                  </span>
                )}
                <button
                  type="button"
                  onClick={() => handleManageIntegration('Google Calendar')}
                  className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-1 px-3 rounded text-sm"
                >
                  Manage
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-300">Outlook Calendar</span>
              <div className="flex items-center">
                {settings.outlookCalendarConnected && (
                  <span className="text-green-500 flex items-center mr-2">
                    <Check size={16} className="mr-1" /> Connected
                  </span>
                )}
                <button
                  type="button"
                  onClick={() => handleManageIntegration('Outlook Calendar')}
                  className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-1 px-3 rounded text-sm"
                >
                  Manage
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-100 flex items-center">
            <Bell className="mr-2" size={24} />
            Notifications
          </h2>
          <div>
            <label htmlFor="notificationPreference" className="block text-sm font-medium text-gray-300">Notification Preference</label>
            <select
              id="notificationPreference"
              name="notificationPreference"
              value={settings.notificationPreference}
              onChange={handleChange}
              className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-gray-100"
            >
              <option value="push">Push Notifications</option>
              <option value="email">Email</option>
              <option value="none">None</option>
            </select>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-100 flex items-center">
            <Lock className="mr-2" size={24} />
            Privacy
          </h2>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="dataSharing"
              name="dataSharing"
              checked={settings.dataSharing}
              onChange={handleChange}
              className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
            />
            <label htmlFor="dataSharing" className="ml-2 block text-sm text-gray-300">
              Allow anonymous data sharing for app improvement
            </label>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Save className="mr-2" size={20} />
            Save Settings
          </button>
        </div>
      </form>
    </div>
  );
};

export default Settings;