import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend } from 'recharts';
import { Clock, Zap, TrendingUp, AlertCircle, ExternalLink } from 'lucide-react';

// Updated mock data for charts
const weeklyData = [
  { name: 'Mon', breaks: 5, duration: 75, bpDecrease: 2 },
  { name: 'Tue', breaks: 7, duration: 105, bpDecrease: 3 },
  { name: 'Wed', breaks: 6, duration: 90, bpDecrease: 2.5 },
  { name: 'Thu', breaks: 8, duration: 120, bpDecrease: 3.5 },
  { name: 'Fri', breaks: 9, duration: 135, bpDecrease: 4 },
  { name: 'Sat', breaks: 4, duration: 60, bpDecrease: 1.5 },
  { name: 'Sun', breaks: 3, duration: 45, bpDecrease: 1 },
];

const monthlyData = [
  { name: 'Week 1', breaks: 30, duration: 450, avgBpDecrease: 2.5 },
  { name: 'Week 2', breaks: 35, duration: 525, avgBpDecrease: 3 },
  { name: 'Week 3', breaks: 40, duration: 600, avgBpDecrease: 3.5 },
  { name: 'Week 4', breaks: 38, duration: 570, avgBpDecrease: 3.2 },
];

const ProgressDashboard = () => {
  const averageSittingTime = weeklyData.reduce((sum, day) => sum + (480 - day.duration), 0) / weeklyData.length; // Assuming 8-hour workday
  const totalBreaks = weeklyData.reduce((sum, day) => sum + day.breaks, 0);
  const totalActiveMinutes = weeklyData.reduce((sum, day) => sum + day.duration, 0);
  const averageBpDecrease = weeklyData.reduce((sum, day) => sum + day.bpDecrease, 0) / weeklyData.length;

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-gray-100">Progress Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <Clock className="text-teal-400 mr-2" size={24} />
            <h2 className="text-xl font-semibold text-gray-100">Total Breaks</h2>
          </div>
          <p className="text-3xl font-bold text-gray-100">{totalBreaks}</p>
          <p className="text-gray-400">This week</p>
        </div>
        
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <Zap className="text-teal-400 mr-2" size={24} />
            <h2 className="text-xl font-semibold text-gray-100">Active Minutes</h2>
          </div>
          <p className="text-3xl font-bold text-gray-100">{totalActiveMinutes}</p>
          <p className="text-gray-400">This week</p>
        </div>
        
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <TrendingUp className="text-teal-400 mr-2" size={24} />
            <h2 className="text-xl font-semibold text-gray-100">Avg. BP Decrease</h2>
          </div>
          <p className="text-3xl font-bold text-gray-100">{averageBpDecrease.toFixed(1)} mmHg</p>
          <p className="text-gray-400">This week</p>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <Clock className="text-teal-400 mr-2" size={24} />
            <h2 className="text-xl font-semibold text-gray-100">Avg. Sitting Time</h2>
          </div>
          <p className="text-3xl font-bold text-gray-100">{averageSittingTime.toFixed(0)}</p>
          <p className="text-gray-400">Minutes per day</p>
        </div>
      </div>
      
      <div className="bg-gray-800 p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-100">Weekly Exercise and Blood Pressure Correlation</h2>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={weeklyData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis dataKey="name" stroke="#9CA3AF" />
            <YAxis yAxisId="left" stroke="#9CA3AF" />
            <YAxis yAxisId="right" orientation="right" stroke="#9CA3AF" />
            <Tooltip 
              contentStyle={{ backgroundColor: '#1F2937', border: 'none', color: '#F3F4F6' }}
              itemStyle={{ color: '#F3F4F6' }}
            />
            <Legend />
            <Line yAxisId="left" type="monotone" dataKey="bpDecrease" stroke="#EF4444" name="BP Decrease (mmHg)" />
            <Line yAxisId="right" type="monotone" dataKey="duration" stroke="#10B981" name="Active Minutes" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-100">Daily Sitting Time vs Active Time</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={weeklyData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="name" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip 
                contentStyle={{ backgroundColor: '#1F2937', border: 'none', color: '#F3F4F6' }}
                itemStyle={{ color: '#F3F4F6' }}
              />
              <Legend />
              <Bar dataKey="duration" fill="#10B981" name="Active Time (min)" />
              <Bar dataKey="breaks" fill="#3B82F6" name="Number of Breaks" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-100">Monthly Progress</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="name" stroke="#9CA3AF" />
              <YAxis yAxisId="left" stroke="#9CA3AF" />
              <YAxis yAxisId="right" orientation="right" stroke="#9CA3AF" />
              <Tooltip 
                contentStyle={{ backgroundColor: '#1F2937', border: 'none', color: '#F3F4F6' }}
                itemStyle={{ color: '#F3F4F6' }}
              />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="breaks" stroke="#3B82F6" name="Total Breaks" />
              <Line yAxisId="right" type="monotone" dataKey="duration" stroke="#10B981" name="Total Active Minutes" />
              <Line yAxisId="left" type="monotone" dataKey="avgBpDecrease" stroke="#EF4444" name="Avg BP Decrease (mmHg)" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      
      <div className="bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4 text-gray-100">Insights</h2>
        <div className="space-y-4">
          <div className="flex items-start">
            <AlertCircle className="text-yellow-500 mr-2 mt-1 flex-shrink-0" size={20} />
            <p className="text-gray-300">Your average daily sitting time is {averageSittingTime.toFixed(0)} minutes. Try to reduce this for better health outcomes.</p>
          </div>
          <div className="flex items-start">
            <AlertCircle className="text-green-500 mr-2 mt-1 flex-shrink-0" size={20} />
            <p className="text-gray-300">
              Your exercise breaks are contributing to an average blood pressure decrease of {averageBpDecrease.toFixed(1)} mmHg. 
              Research shows that all amounts of walking can significantly reduce blood pressure by 4 to 5 mmHg compared with sitting all day.
              <a href="https://www.health.harvard.edu/blog/even-light-walking-may-lower-blood-pressure-in-those-with-hypertension-202302072893" target="_blank" rel="noopener noreferrer" className="text-teal-400 hover:text-teal-300 ml-1">
                Learn more <ExternalLink size={14} className="inline" />
              </a>
            </p>
          </div>
          <div className="flex items-start">
            <AlertCircle className="text-blue-500 mr-2 mt-1 flex-shrink-0" size={20} />
            <p className="text-gray-300">
              You've taken {totalBreaks} breaks this week, totaling {totalActiveMinutes} active minutes. 
              Regular breaks and physical activity can help improve focus, productivity, and overall health.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressDashboard;