import React, { useState, useRef, useEffect } from 'react';
import { Send, UserPlus, Paperclip, Smile, Users, User } from 'lucide-react';

const ChatPage = () => {
  const [selectedChat, setSelectedChat] = useState('team');
  const [inputMessage, setInputMessage] = useState('');
  const [teamMessages, setTeamMessages] = useState([
    { sender: 'Coach Megan', message: 'Great news, team! Our exercise time is on the rise. We\'ve seen a significant increase in active minutes across the board.', time: '10:30 AM' },
    { sender: 'Coach Megan', message: 'One team member in particular, Sarah, has really stood out. She\'s consistently exceeded her daily activity goals and has inspired others to push themselves further.', time: '10:32 AM' },
    { sender: 'Coach Megan', message: 'Keep up the great work, everyone! Remember, small consistent efforts lead to big results.', time: '10:35 AM' },
    { sender: 'User', message: 'That\'s awesome! Sarah\'s dedication is really motivating.', time: '10:40 AM' },
  ]);
  const [coachMessages, setCoachMessages] = useState([
    { sender: 'Coach Megan', message: 'Hi there! I\'ve noticed that your sedentary time has been creeping up, especially on weekends.', time: '11:00 AM' },
    { sender: 'Coach Megan', message: 'While it\'s natural to want to relax, too much sitting can offset the great progress you\'re making during the week.', time: '11:02 AM' },
    { sender: 'Coach Megan', message: 'I\'ve set a reminder for you on weekends to encourage more movement. Even small activities like a short walk or some stretching can make a big difference.', time: '11:05 AM' },
    { sender: 'User', message: 'Thanks for noticing, Coach Megan. I\'ll try to be more active on weekends.', time: '11:10 AM' },
  ]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [teamMessages, coachMessages]);

  const currentMessages = selectedChat === 'team' ? teamMessages : coachMessages;

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputMessage.trim() !== '') {
      const newMessage = {
        sender: 'User',
        message: inputMessage,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      };
      
      if (selectedChat === 'team') {
        setTeamMessages([...teamMessages, newMessage]);
      } else {
        setCoachMessages([...coachMessages, newMessage]);
      }

      setInputMessage('');
    }
  };

  return (
    <div className="p-4 max-w-4xl mx-auto h-screen flex flex-col">
      <h1 className="text-3xl font-bold mb-6 text-gray-100">MoveWell Chat</h1>
      
      <div className="bg-gray-800 rounded-lg shadow-md overflow-hidden flex-grow flex flex-col">
        <div className="flex border-b border-gray-700">
          <button
            className={`flex-1 py-3 px-4 ${selectedChat === 'team' ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700'} transition-colors duration-200 ease-in-out flex items-center justify-center`}
            onClick={() => setSelectedChat('team')}
          >
            <Users size={20} className="mr-2" />
            <span className="hidden sm:inline">Team Chat</span>
          </button>
          <button
            className={`flex-1 py-3 px-4 ${selectedChat === 'coach' ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700'} transition-colors duration-200 ease-in-out flex items-center justify-center`}
            onClick={() => setSelectedChat('coach')}
          >
            <User size={20} className="mr-2" />
            <span className="hidden sm:inline">Coach Chat</span>
          </button>
        </div>
        
        <div className="flex-grow overflow-y-auto p-4 space-y-4">
          {currentMessages.map((msg, index) => (
            <div key={index} className={`flex ${msg.sender === 'User' ? 'justify-end' : 'justify-start'}`}>
              <div className={`max-w-[75%] p-3 rounded-lg ${msg.sender === 'User' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-200'} shadow-md transition-all duration-300 hover:shadow-lg`}>
                {msg.sender !== 'User' && <p className="font-semibold text-sm">{msg.sender}</p>}
                <p className="break-words">{msg.message}</p>
                <p className="text-xs text-gray-400 mt-1">{msg.time}</p>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        
        <form onSubmit={handleSendMessage} className="border-t border-gray-700 p-4 flex items-center">
          <button type="button" className="text-gray-400 hover:text-gray-200 mr-2 transition-colors duration-200">
            <Paperclip size={20} />
          </button>
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type a message..."
            className="flex-grow bg-gray-700 text-white rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
          />
          <button type="button" className="text-gray-400 hover:text-gray-200 mx-2 transition-colors duration-200">
            <Smile size={20} />
          </button>
          <button type="submit" className="bg-blue-600 text-white rounded-full p-2 hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500">
            <Send size={20} />
          </button>
        </form>
      </div>
      
      {selectedChat === 'team' && (
        <div className="mt-4 bg-gray-800 rounded-lg shadow-md p-4">
          <h2 className="text-xl font-semibold mb-2 text-gray-100">Team Members</h2>
          <ul className="space-y-2">
            <li className="flex items-center text-gray-300">
              <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
              Coach Megan
            </li>
            <li className="flex items-center text-gray-300">
              <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
              Sarah (MVP)
            </li>
            <li className="flex items-center text-gray-300">
              <div className="w-2 h-2 bg-gray-500 rounded-full mr-2"></div>
              John (Offline)
            </li>
          </ul>
          <button className="mt-4 flex items-center text-blue-400 hover:text-blue-300 transition-colors duration-200">
            <UserPlus size={16} className="mr-1" />
            Invite Team Member
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatPage;